import React, { useCallback } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useStateContext } from "../contexts/ContextProvider";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

const BACKEND_API = process.env.REACT_APP_BACKEND_API;
const STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

const stripePromise = loadStripe(STRIPE_KEY);

const CheckoutForm = () => {
  const { cart, rateIds, usersHotel, roomNumber } = useStateContext();

  //if cart or hotel are empty, navigate back to orders page.

  const body = {
    products: cart,
    tax_rates: rateIds,
    hotel: usersHotel,
    room: roomNumber,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  const fetchClientSecret = useCallback(async () => {
    const res = await fetch(`${BACKEND_API}/create-checkout-session`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    });
    const data = await res.json();
    return data.clientSecret;
  }, []);

  const options = { fetchClientSecret };

  return (
    <div id="checkout" className="mt-32">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
      {process.env.REACT_APP_ENVIRONMENT !== "PROD" && (
        <div className="flex justify-center text-lg">
          <div className="block fixed bottom-0 right-0 p-5 w-screen bg-light-gray border-2 border-red-600">
            <div className="flex justify-center">
              <div className="block">
                <div>
                  Test Credit Card:{" "}
                  <span className="font-bold">4242-4242-4242-4242</span>
                </div>
                <div className="flex">
                  <div className="mr-10">
                    EXP.: <span className="font-bold">09/42</span>
                  </div>
                  <div>
                    CVC: <span className="font-bold">999</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckoutForm;
