export const liveTaxRates = [
  {
    id: 1,
    state: "CT",
    rate_ids: ["txr_1PKF7hKBxDm065Ck1pz8S8jJ"],
    total_rate: 0.0735,
  },
  {
    id: 2,
    state: "NH",
    rate_ids: ["txr_1PLX1DKBxDm065CkVV9hsQgG"],
    total_rate: 0.085,
  },
  {
    id: 3,
    state: "CA",
    rate_ids: ["txr_1PLWxxKBxDm065CkOl9arGbm"],
    total_rate: 0.08625,
  },
  {
    id: 4,
    state: "TX",
    rate_ids: ["txr_1PLX2MKBxDm065CkJUkvQuF1"],
    total_rate: 0.0825,
  },
  {
    id: 5,
    state: "NY",
    rate_ids: ["txr_1PLX3GKBxDm065CkwcsEKM89"],
    total_rate: 0.08875,
  },
  {
    id: 6,
    state: "MD",
    rate_ids: ["txr_1PLX3xKBxDm065CkBbxXnZlW"],
    total_rate: 0.06,
  },
  {
    id: 7,
    state: "HI",
    rate_ids: ["txr_1PLX4lKBxDm065CkgXI70oPt"],
    total_rate: 0.045,
  },
  {
    id: 8,
    state: "DC",
    rate_ids: ["txr_1PLX5YKBxDm065CkMRvDUPNo"],
    total_rate: 0.06,
  },
  {
    id: 9,
    state: "PE",
    rate_ids: ["txr_1PLX6LKBxDm065CkuIE2SF4w"],
    total_rate: 0.15,
  },
  {
    id: 7,
    state: "CO",
    rate_ids: ["txr_1PLX74KBxDm065CkAWcAXPa5"],
    total_rate: 0.029,
  },
];
