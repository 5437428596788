import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import OrderTracker from "../components/OrderTracker";
import { ordersData } from "../data/dummy";

const BACKEND_API = process.env.REACT_APP_BACKEND_API;

const Return = () => {
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [count, setCount] = useState(0);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sessionId = urlParams.get("session_id");

  const requestDelay = 180000; // 3-mins

  const apiCall = async () => {
    await fetch(`${BACKEND_API}/session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        setPaymentStatus(data.payment_status);
        setOrderStatus(data.order_status);
        setCustomerEmail(data.customer_details.email);
        setCustomerName(data.customer_details.name);
        console.log("customer data: ", data);
      });
  };

  useEffect(() => {
    apiCall();

    setTimeout(() => {
      if (orderStatus === "DELIVERED") {
        clearTimeout();
      } else {
        setCount(count + 1);
      }
    }, requestDelay);
  }, [count]);

  if (paymentStatus === "open") {
    return <Navigate to="/checkout" />;
  }

  if (paymentStatus === "complete") {
    return (
      <OrderTracker
        name={customerName}
        email={customerEmail}
        orderStatus={orderStatus}
      />
    );
  }

  return null;
};

export default Return;
