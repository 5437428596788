import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Transition } from "react-transition-group";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";

import { useStateContext } from "../contexts/ContextProvider";
import AutoComplete from "../components/AutoComplete";
import { sortHotels } from "../data/helpers/Utils";
import { Button, CalculateDistance } from "../components";
import Dropdown from "../components/Dropdown";
import BounceLoader from "react-spinners/PulseLoader";

const Order = () => {
  const {
    latitude,
    longitude,
    setLatitude,
    setLongitude,
    usersHotel,
    setUsersHotel,
    collectRoomNumber,
    setCollectRoomNumber,
    roomNumber,
    setRoomNumber,
    errorAnimation,
    setErrorAnimation,
    sortedHotels,
    setSortedHotels,
  } = useStateContext();

  const [selectLocation, setSelectLocation] = useState(false);
  const [searchNearby, setSearchNearby] = useState(false);
  const [searchByAddress, setSearchByAddress] = useState(false);
  const [hasGPS, setHasGPS] = useState(true);

  const success = (position) => {
    const newLatitude = position.coords.latitude;
    setLatitude(newLatitude);
    const newLongitude = position.coords.longitude;
    setLongitude(newLongitude);
  };

  const error = () => {
    setHasGPS(false);
    console.log("Unable to retrieve your location");
  };

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(success, error);
  } else {
    console.log("Geolocation not supported");
  }

  const cleanUp = () => {
    const newlySortedHotels = sortHotels(latitude, longitude);
    setSortedHotels(newlySortedHotels);
  };

  useEffect(() => {
    cleanUp();
  }, [latitude, longitude]);

  const handleRoomNumber = () => {
    if (selectLocation) {
      setSelectLocation(false);
    } else {
      if (usersHotel.length === 0) {
        const newHotel = sortedHotels[0];
        setUsersHotel(newHotel);
      }
    }
    setCollectRoomNumber(true);
  };

  const errorCheck = () => {
    if (usersHotel.length === 0) {
      setErrorAnimation("animate-shake text-red-600");
      setTimeout(() => {
        setErrorAnimation("");
      }, 500);
    } else {
      handleRoomNumber();
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setRoomNumber(e.target.value);
  };

  const navigate = useNavigate();

  const goToMenu = () => {
    if (roomNumber) {
      navigate("/menu");
      setCollectRoomNumber(false);
    } else {
      setErrorAnimation("animate-shake text-red-600");
      setTimeout(() => {
        setErrorAnimation("");
      }, 500);
    }
  };

  const handleNewLocation = () => {
    setRoomNumber("");
    setSelectLocation(!selectLocation);
    setSearchNearby(false);
    setSearchByAddress(false);
  };

  const openNearby = () => {
    setSearchNearby(!searchNearby);
    if (searchByAddress) {
      setSearchByAddress(!searchByAddress);
    }
  };

  const openByAddress = () => {
    setSearchByAddress(!searchByAddress);
    if (searchNearby) {
      setSearchNearby(!searchNearby);
    }
  };

  return selectLocation ? (
    <div className="flex justify-center min-h-screen min-w-screen">
      <div className="rounded-3xl drop-shadow-2xl bg-white">
        <div
          className={`flex justify-center h-10 m-10 text-2xl ${errorAnimation}`}
        >
          Select Your Hotel:
        </div>

        {/* Search nearby Dropdown */}
        {hasGPS && (
          <>
            <div
              className={`${
                searchNearby ? "hidden" : "flex"
              } justify-center h-20 items-center mt-5 `}
            >
              <button onClick={openNearby}>
                <div className="flex justify-end items-center text-2xl h-20 bg-light-gray">
                  <div className="flex justify-around items-center w-96 text-2xl p-2">
                    Choose from Nearby Hotels:
                    <IoChevronDown />
                  </div>
                </div>
              </button>
            </div>
            <Transition in={searchNearby} timeout={0}>
              {(state) => (
                <div
                  className={`${
                    searchNearby ? "flex" : "hidden"
                  } justify-center h-52 mt-5 `}
                  style={{
                    transition: "height, opacity, .1s ease-in-out",
                    height: state === "entered" ? "160px" : "100px",
                    opacity: state === "entered" ? 1 : 0.2,
                  }}
                >
                  <div className="flex justify-end text-2xl h-40 bg-light-gray">
                    <div className="fixed justify-end h-16 text-2xl  ">
                      <IoChevronUp />
                    </div>
                    <div
                      onClick={openNearby}
                      className="fixed cursor-pointer h-16 w-96 text-lg pt-4 pl-4"
                    >
                      -- Hotels Nearby
                    </div>
                    <div className="flex justify-center items-center pt-10">
                      <div
                        className="flex justify-center h-20 pt-4 border-4 border-black bg-white"
                        style={{
                          width: 384,
                        }}
                      >
                        <Dropdown placeholder="Select from Nearby Hotels" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Transition>
          </>
        )}
        {/* Search By Address AutoComplete */}
        <div
          className={`${
            searchByAddress ? "hidden" : "flex"
          } justify-center w-96 h-20 items-center p-0 `}
        >
          <button onClick={openByAddress}>
            <div className="flex justify-end items-center text-2xl h-20 bg-light-gray w-96 mt-5">
              <div className="flex justify-between items-center w-96 m-2 text-2xl p-2">
                Search by Address:
                <IoChevronDown />
              </div>
            </div>
          </button>
        </div>
        <Transition in={searchByAddress} timeout={0}>
          {(state) => (
            <div
              className={`${
                searchByAddress ? "flex" : "hidden"
              } justify-center h-52 mt-1`}
              style={{
                transition: "height, opacity, .1s ease-in-out",
                height: state === "entered" ? "160px" : "100px",
                opacity: state === "entered" ? 1 : 0.2,
              }}
            >
              <div className="flex justify-end text-2xl h-40 bg-light-gray ">
                <div className="fixed h-10 text-2xl pt-5 pr-3 ">
                  <IoChevronUp />
                </div>
                <div
                  onClick={openByAddress}
                  className="fixed cursor-pointer h-16 w-96 text-lg pt-4 pl-4"
                >
                  -- By Address
                </div>
                <div className="flex justify-center items-center pt-10">
                  <div
                    className="flex justify-center h-20 pt-4 border-4 border-black bg-white"
                    style={{
                      width: 384,
                    }}
                  >
                    <AutoComplete placeholder="Enter Hotel Address" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </Transition>

        {/* Back & Next Buttons */}
        <div className="flex justify-center mt-10 gap-5">
          <Button
            color="black"
            bgColor="gray"
            text="Back"
            borderRadius="10px"
            width="full"
            size="2xl w-24"
            customFunc={handleNewLocation}
          />
          <Button
            color="white"
            bgColor="Black"
            text="Next"
            borderRadius="10px"
            width="full"
            size="2xl w-24"
            customFunc={errorCheck}
          />
        </div>
      </div>
    </div>
  ) : collectRoomNumber ? ( //collect room number
    <div className="flex justify-center min-h-screen min-w-screen">
      <div className="w-96 drop-shadow-2xl rounded-3xl bg-white">
        <div
          className={`flex justify-center mt-40 m-10 text-xl ${errorAnimation}`}
        >
          What is your room number?:
        </div>
        <div className="flex justify-center h-10 m-1 text-lg">
          <input
            className="border-2 border-light-gray rounded w-40 text-center"
            type="number"
            placeholder="Room Number"
            value={roomNumber ? roomNumber : ""}
            onChange={handleChange}
            required
          ></input>
        </div>
        <div className="flex justify-center mt-10">
          <div className="flex gap-5">
            <Button
              color="white"
              bgColor="Black"
              text="NEXT"
              borderRadius="10px"
              width="full"
              size="2xl w-24"
              customFunc={goToMenu}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    // Order Pages Begins here:
    <div className="flex justify-center min-h-screen min-w-screen ">
      <div className="w-96 drop-shadow-2xl rounded-3xl bg-white">
        <div className="flex justify-center text-6xl font-bold mt-5">
          Snappily
        </div>
        <div className="flex justify-center text-lg mt-3">
          The World's Fastest Takeout App
        </div>
        <div className="flex justify-center bold pl-5 pr-5 mt-10">
          <div className="p-3 border-4 border-black rounded-3xl">
            {latitude && longitude ? (
              <>
                <div className="flex justify-center text-lg p-3">
                  It looks like you're staying here:
                </div>
                <div className="flex justify-center">
                  <img
                    className="rounded-2xl full w-48 object-cover"
                    src={
                      usersHotel.length !== 0
                        ? usersHotel.image
                        : sortedHotels[0].image
                    }
                    alt={
                      usersHotel.length !== 0
                        ? usersHotel.name
                        : sortedHotels[0].name
                    }
                  />
                </div>

                <div className="m-3">
                  <div className="flex justify-center text-xl text-center font-bold">
                    {usersHotel.length !== 0
                      ? usersHotel.name
                      : sortedHotels[0].name}
                  </div>
                  <div className="flex justify-center indent-0 text-lg text-center">
                    {usersHotel.length !== 0
                      ? usersHotel.street_address +
                        ", " +
                        usersHotel.city +
                        ", " +
                        usersHotel.state
                      : sortedHotels[0].street_address +
                        ", " +
                        sortedHotels[0].city +
                        ", " +
                        sortedHotels[0].state}
                  </div>

                  <div className="flex justify-center indent-0 text-lg mt-2 text-red-500">
                    {latitude && longitude
                      ? usersHotel.length !== 0
                        ? usersHotel.distance
                        : sortedHotels[0].distance + " miles away"
                      : ""}
                  </div>
                </div>
              </>
            ) : hasGPS ? (
              <BounceLoader />
            ) : (
              "Location Permissions Are Off"
            )}
          </div>
        </div>

        <div className="flex justify-center text-wrap mt-3 text-red-500">
          {latitude && longitude
            ? "" //`Lat: ${latitude.toFixed(6)}  Long: ${longitude.toFixed(6)}`
            : hasGPS
            ? "Calculating Location..."
            : ""}
        </div>
        {latitude && (
          <>
            <div className="flex justify-center mt-5 text-2xl font-bold">
              {hasGPS ? "Is this correct?" : "Add Delivery Location"}
            </div>

            <div className="flex justify-center m-5">
              <div className="flex gap-5">
                <Button
                  color="white"
                  bgColor="Black"
                  text="YES"
                  borderRadius="10px"
                  width="full"
                  size="2xl w-24"
                  customFunc={handleRoomNumber}
                />

                <Button
                  color={hasGPS ? "black" : "white"}
                  bgColor={hasGPS ? "gray" : "Black"}
                  text={hasGPS ? "NO" : "Add Address"}
                  borderRadius="10px"
                  width="full"
                  size={hasGPS ? "2xl w-24" : "2xl"}
                  customFunc={handleNewLocation}
                />
              </div>
            </div>
          </>
        )}
        {!hasGPS && (
          <>
            <div className="flex justify-center text-wrap mt-10 text-lg font-bold">
              Add Delivery Location Manually
            </div>

            <div className="flex justify-center m-5">
              <div className="flex gap-5">
                <Button
                  color="white"
                  bgColor="Black"
                  text="Add Address"
                  borderRadius="10px"
                  width="full"
                  size="2xl"
                  customFunc={handleNewLocation}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Order;
