export const liveMenu = [
  {
    id: 1,
    name: "Seseme Chicken",
    description:
      "A Chinese-American classic, served with your choice of pork fried rice, white rice, or french fries.",
    calories: 1170,
    category: "dinner",
    price: 12.99,
    image: "https://i.ibb.co/TRTfWqd/dinner-04.jpg",
    options: [{ name: "Fried Rice" }, { name: "White Rice" }],
    selectedOptions: [],
    qty: 1,
  },
  {
    id: 2,
    name: "Beef & Broccoli",
    description:
      "Tender beef mixed with sauteed broccoli, served with your choice of pork fried rice, white rice, or french fries.",
    calories: 1100,
    category: "dinner",
    price: 12.99,
    image: "https://i.ibb.co/yqLgbHx/dinner-05.webp",
    options: [{ name: "Fried Rice" }, { name: "White Rice" }],
    selectedOptions: [],
    qty: 1,
  },
  {
    id: 3,
    name: "Chicken & Broccoli",
    description:
      "Tender chicken mixed with sauteed broccoli, served with your choice of pork fried rice, white rice, or french fries.",
    calories: 1290,
    category: "dinner",
    price: 12.99,
    image: "https://i.ibb.co/NVL3pKX/dinner-06.jpg",
    options: [{ name: "Fried Rice" }, { name: "White Rice" }],
    selectedOptions: [],
    qty: 1,
  },
  {
    id: 4,
    name: "Fish & Chips",
    description: "Fresh Atlantic Cod, served with all-american french fries.",
    calories: 870,
    category: "dinner",
    price: 12.99,
    image: "https://i.ibb.co/RDH9Xyn/dinner-07.jpg",
    options: null,
    qty: 1,
  },
  {
    id: 5,
    name: "Chicken Wings",
    description:
      "One huge helping of Chinese-style chicken wings, served with your choice of fried rice, white rice, or french fries.",
    calories: 950,
    category: "dinner",
    price: 12.99,
    image: "https://i.ibb.co/ySmkftz/dinner-08.jpg",
    options: null,
    qty: 1,
  },
  {
    id: 6,
    name: "Chicken Nuggets",
    description:
      "A delicious heaping of tender chicken nuggets, served with your choice of fried rice, white rice, or french fries.",
    calories: 900,
    category: "dinner",
    price: 12.99,
    image: "https://i.ibb.co/SXs480T/dinner-09.jpg",
    options: null,
    qty: 1,
  },
];
