import React, { useEffect } from "react";
import { FiShoppingCart } from "react-icons/fi";
import { BsChatLeft } from "react-icons/bs";
import { RiNotification3Line } from "react-icons/ri";
import { MdKeyboardArrowDown } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { useNavigate } from "react-router-dom";

import avatar from "../data/avatar.jpg";
import {
  Cart,
  Chat,
  Notification,
  UserProfile,
  NavButton,
  HamburgerButton,
} from ".";
import { useStateContext } from "../contexts/ContextProvider";

const Navbar = () => {
  const {
    currentColor,
    activeMenu,
    setActiveMenu,
    handleClick,
    isClicked,
    setScreenSize,
    screenSize,
    changeAdminMode,
    cart,
    cartTotal,
    adminMode,
    handleMenuClick,
  } = useStateContext();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 950) {
      setActiveMenu(false);
    } else {
      // setActiveMenu(true);
    }
  }, [screenSize]);

  const navigate = useNavigate();

  const relocate = () => {
    if (adminMode) {
      navigate("/");
      setActiveMenu(false);
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <div className="flex relative justify-between items-center p-2  w-full mx-5">
      {<HamburgerButton />}

      <div
        className="flex items-center m-2 gap-2"
        style={{ color: currentColor }}
      >
        <label htmlFor="admin">Admin Mode:</label>
        <input
          id="admin"
          type="checkbox"
          onClick={() => (changeAdminMode(), relocate())}
        />
      </div>
      <div className="flex items-center">
        {adminMode ? (
          <>
            <NavButton
              title="Chat"
              dotColor="#03C9D7"
              customFunc={() => handleClick("chat")}
              color={currentColor}
              icon={<BsChatLeft />}
            />
            <NavButton
              title="Notification"
              dotColor="rgb(254, 201, 15)"
              customFunc={() => handleClick("notification")}
              color={currentColor}
              icon={<RiNotification3Line />}
            />
            <TooltipComponent content="Profile" position="BottomCenter">
              <div
                className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
                onClick={() => handleClick("userProfile")}
              >
                <img
                  className="rounded-full w-8 h-8 object-cover"
                  src={avatar}
                  alt="user-profile"
                />
                <p>
                  <span className="text-gray-400 text-14">Hi, </span>
                  <span className="text-gray-400 font-bold ml-1 text-14">
                    D. William
                  </span>
                </p>
                <MdKeyboardArrowDown className="text-gray-400 text-14" />
              </div>
            </TooltipComponent>
          </>
        ) : (
          <TooltipComponent content="Checkout" position="BottomCenter">
            <div
              className="flex items-center rounded-full p-3 text-base hover:bg-light-gray"
              onClick={() => handleClick("cart")}
            >
              {cart.reduce((tot, num) => tot + num.qty, 0)}
              <button
                className="relative text-3xl rounded-full p-3 hover:bg-light-gray"
                type="button"
                style={{ color: currentColor }}
                onClick={() => (handleClick("cart"), handleMenuClick())}
              >
                <FiShoppingCart />
              </button>
              <p
                className="dark:text-gray-400 text-3xl rounded-full p-3"
                style={{ color: currentColor, cursor: "pointer" }}
              >
                ${cartTotal}
              </p>
            </div>
          </TooltipComponent>
        )}
        {isClicked.cart && <Cart />}
        {isClicked.chat && <Chat />}
        {isClicked.notification && <Notification />}
        {isClicked.userProfile && <UserProfile />}
      </div>
    </div>
  );
};

export default Navbar;
