import React from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { IoTrashOutline } from "react-icons/io5";

import { useStateContext } from "../contexts/ContextProvider";
import { taxRates } from "../data/tax-rates";
import { Button } from ".";

const Cart = () => {
  const {
    currentColor,
    handleClick,
    cart,
    cartTotal,
    addToCart,
    removeOneFromCart,
    removeAllFromCart,
    errorAnimation,
    setErrorAnimation,
    rateIds,
    totalTaxRate,
  } = useStateContext();

  let tax = totalTaxRate;

  const navigate = useNavigate();

  const navigateTo = () => {
    if (cart.length !== 0) {
      handleClick("cart");
      navigate("/checkout");
    } else {
      setErrorAnimation("animate-shake text-red-600");
      setTimeout(() => {
        setErrorAnimation("");
      }, 500);
      clearTimeout();
    }
  };

  return (
    <div className="bg-half-transparent fixed nav-item w-full top-0 right-0 bottom-0">
      <div
        className="fixed top-0 right-0 min-w-[375px] h-screen pb-10 duration-1000 ease-in-out overflow-y-auto overflow-x-hidden 
       dark:text-gray-200 transition-all dark:bg-[#484B52] bg-white md:w-400"
      >
        <div className="flex justify-between items-center">
          <p className="font-semibold text-2xl pl-5">Shopping Cart</p>
          <button
            type="buttons"
            onClick={() => handleClick("cart")}
            style={{ color: currentColor, borderRadius: "50%" }}
            className="text-2xl p-3
            hover:bg-light-gray hover:dark:bg-main-dark-bg"
          >
            <MdOutlineCancel />
          </button>
        </div>
        {cart.length !== 0 ? (
          cart.map((item, index) => (
            <div key={index}>
              <div>
                <div className="flex items-center leading-8 gap-5 border-b-1 border-color dark:border-gray-600 mx-4 p-4">
                  <img
                    className="rounded-lg h-80 w-24 object-cover"
                    src={item.image}
                    alt=""
                  />
                  <div>
                    <p className="font-semibold ">{item.name}</p>
                    {item.selectedOptions && (
                      <p className="text-gray-600 dark:text-gray-400 text-sm font-semibold">
                        {item.selectedOptions.map(
                          (option, index) => "w/ " + option.value + " "
                        )}
                      </p>
                    )}
                    <div className="flex gap-4 mt-5 items-center">
                      <p className="font-semibold text-lg">${item.price}</p>
                      <div className="flex items-center border-1 border-r-0 border-color rounded">
                        <p className="p-2 border-r-1 dark:border-gray-600 border-color text-red-600 ">
                          <AiOutlineMinus
                            onClick={() => removeOneFromCart(item)}
                          />
                        </p>
                        <p className="p-2 border-r-1 border-color dark:border-gray-600 text-green-600">
                          {item.qty}
                        </p>
                        <p className="p-2 border-r-1 border-color dark:border-gray-600 text-green-600">
                          <AiOutlinePlus
                            onClick={() =>
                              addToCart(item, item.selectedOptions[0])
                            }
                          />
                        </p>
                      </div>
                      <p className="p-2 text-black-600">
                        <button
                          type="buttons"
                          onClick={() => removeAllFromCart(item)}
                          style={{ color: currentColor }}
                          className="text-2xl p-3 hover:bg-light-gray hover:dark:bg-main-dark-bg"
                        >
                          <IoTrashOutline />
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>
            <p
              id="empty-cart"
              className={`flex justify-center m-10 font-semibold text-lg ${errorAnimation}`}
            >
              Your Cart Is Empty
            </p>
          </div>
        )}
        <div className="m-5">
          <div className="flex justify-between items-center">
            <p className="text-gray-500 dark:text-gray-200">Sub Total</p>
            <p className="font-semibold">${cartTotal}</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-gray-500 dark:text-gray-200">
              {`Tax (${(tax * 100).toFixed(2)}%)`}
            </p>
            <p className="font-semibold">
              ${parseFloat(cartTotal * tax).toFixed(2)}
            </p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-gray-500 dark:text-gray-200">Total</p>
            <p className="font-semibold">
              ${parseFloat(cartTotal * (1 + tax)).toFixed(2)}
            </p>
          </div>
        </div>
        <div className="flex justify-center mb-20">
          <Button
            size="2xl"
            color="white"
            bgColor={currentColor}
            text="Checkout"
            borderRadius="10px"
            width="full"
            customFunc={() => navigateTo()}
          />
        </div>
      </div>
    </div>
  );
};

export default Cart;
