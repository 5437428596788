import React, { createContext, useContext, useState, useEffect } from "react";
import { liveTaxRates } from "../data/tax-rates";
import { demoTaxRates } from "../data/demo-tax-rates";

const StateContext = createContext();

let taxRates;
if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
  taxRates = liveTaxRates;
} else {
  taxRates = demoTaxRates;
}

const initialState = {
  chat: false,
  cart: false,
  rate: false,
  userProfile: false,
  notification: false,
};

const initialMenuState = {
  breakfast: false,
  lunch: false,
  dinner: false,
  sides: false,
  drinks: false,
};

export const ContextProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState("black");
  const [currentMode, setCurrentMode] = useState("Light");
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);
  const [isClicked, setIsClicked] = useState(initialState);
  const [isMenuClicked, setMenuClicked] = useState(initialMenuState);
  const [cart, setCart] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [adminMode, setAdminMode] = useState(false);
  const [errorAnimation, setErrorAnimation] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [usersHotel, setUsersHotel] = useState([]);
  const [collectRoomNumber, setCollectRoomNumber] = useState(false);
  const [roomNumber, setRoomNumber] = useState(null);
  const [sortedHotels, setSortedHotels] = useState([]);
  const [rateIds, setRateIds] = useState([]);
  const [totalTaxRate, setTotalTaxRate] = useState(0);

  const calcCartTotal = () => {
    let totalVal = cart.reduce((tot, num) => tot + num.price * num.qty, 0);
    setCartTotal(totalVal.toFixed(2));
  };

  // TODO: calculate total tax on a per item basis, and save as taxTotal, to avoid stripe rounding errors.
  //you can copy calcCartTotal from above to logic to achieve this

  useEffect(() => {
    calcCartTotal();
  }, [cart]);

  const getTaxRates = () => {
    if (usersHotel.length !== 0) {
      const jurisdiction = taxRates.find(
        (loc) => loc.state === usersHotel.state
      );
      setRateIds(jurisdiction.rate_ids);
      setTotalTaxRate(jurisdiction.total_rate);
    }
  };

  useEffect(() => {
    getTaxRates();
  }, [usersHotel]);

  const addToCart = (item, option) => {
    let cartItems = [...cart];
    if (cartItems.some((x) => x.id === item.id)) {
      const currentItem = cartItems.find((cartItem) => cartItem.id === item.id);
      currentItem.qty++;
      if (option) {
        currentItem.selectedOptions.push(option);
      }
      setCart(cartItems);
    } else {
      item.qty = 1;
      if (option) {
        item.selectedOptions.push(option);
      }
      setCart([...cart, item]);
    }
  };

  const removeOneFromCart = (item) => {
    let cartItems = [...cart];
    if (item.qty && item.qty > 1) {
      const currentItem = cartItems.find((cartItem) => cartItem.id === item.id);
      currentItem.qty--;
      currentItem.selectedOptions.pop();
      setCart(cartItems);
    } else {
      cartItems
        .find((cartItem) => cartItem.id === item.id)
        .selectedOptions.pop();
      const hardCopy = cartItems.filter(
        (cartItem) => cartItem.id !== item.id && cartItem.index === item.index
      );
      setCart(hardCopy);
    }
  };

  const removeAllFromCart = (item) => {
    let cartItems = [...cart];
    cartItems.find((cartItem) => cartItem.id === item.id).selectedOptions = [];
    const hardCopy = cartItems.filter(
      (cartItem) => cartItem.id !== item.id && cartItem.index === item.index
    );
    setCart(hardCopy);
  };

  const changeAdminMode = () => {
    setAdminMode(!adminMode);
    setIsClicked({ cart: false });
    setCart([]);
  };

  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem("themeMode", e.target.value);
    setThemeSettings(false);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem("colorMode", color);
    setThemeSettings(false);
  };

  const handleClick = (clicked) => {
    setIsClicked({ ...initialState, [clicked]: !isClicked[clicked] });
  };

  const handleMenuClick = (clicked) => {
    setMenuClicked({ ...initialMenuState, [clicked]: !isMenuClicked[clicked] });
  };

  return (
    <StateContext.Provider
      value={{
        currentColor,
        currentMode,
        activeMenu,
        screenSize,
        setScreenSize,
        handleClick,
        isClicked,
        initialState,
        setIsClicked,
        isMenuClicked,
        setMenuClicked,
        handleMenuClick,
        setActiveMenu,
        setCurrentColor,
        setCurrentMode,
        setMode,
        setColor,
        themeSettings,
        setThemeSettings,
        cart,
        setCart,
        cartTotal,
        setCartTotal,
        addToCart,
        removeOneFromCart,
        removeAllFromCart,
        adminMode,
        changeAdminMode,
        errorAnimation,
        setErrorAnimation,
        latitude,
        setLatitude,
        longitude,
        setLongitude,
        usersHotel,
        setUsersHotel,
        roomNumber,
        setRoomNumber,
        collectRoomNumber,
        setCollectRoomNumber,
        sortedHotels,
        setSortedHotels,
        rateIds,
        setRateIds,
        totalTaxRate,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
