import React from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { NavButton } from "../components";
import { useStateContext } from "../contexts/ContextProvider";

const HamburgerButton = () => {
  const { activeMenu, currentColor, setActiveMenu } = useStateContext();

  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  return (
    <div>
      <NavButton
        title="Sidebar"
        customFunc={handleActiveMenu}
        color={currentColor}
        icon={<AiOutlineMenu />}
      />
    </div>
  );
};

export default HamburgerButton;
