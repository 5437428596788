import React, { useState, useEffect } from "react";
import { useStateContext } from "../contexts/ContextProvider";

import { RiStarSFill } from "react-icons/ri";
import { Button } from "../components";
import { IconContext } from "react-icons";
import { MdOutlineCancel } from "react-icons/md";

const OrderTracker = ({ email, name, orderStatus }) => {
  const { currentColor, handleClick, isClicked } = useStateContext();
  const maxChars = 300;

  //const orderStatus = "RECEIVED";

  const [progress, setProgress] = useState(0);
  const [rating, setRating] = useState(0);
  const [isRated, setIsRated] = useState(false);
  const [hover, setHover] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [charCount, setCharCount] = useState(maxChars);

  const fullName = name.split(" ");
  const firstName = fullName[0];

  useEffect(() => {
    switch (progress <= 100) {
      case orderStatus === "RECEIVED":
        setProgress(0);
        break;
      case orderStatus === "BEING PREPARED":
        setProgress(30);
        break;
      case orderStatus === "ENROUTE":
        setProgress(60);
        break;
      case orderStatus === "DELIVERED":
        setProgress(100);
        // setTimeout(() => {
        //   handleClick("rate");
        //   clearTimeout();
        // }, 1000);
        break;
    }
  }, [orderStatus]);

  const handleChange = (e) => {
    e.preventDefault();
    setFeedback(e.target.value);
    setCharCount(maxChars - e.target.value.length);
  };

  const handleSubmit = () => {
    // console.log(
    //   "rating: ",
    //   rating,
    //   " Feedback: ",
    //   feedback,
    //   " Chars remaining: ",
    //   charCount
    // );
    if (rating !== 0) {
      setIsRated(true);
    }
  };

  const handleClose = () => {
    handleClick("rate");
  };

  return (
    <section id="success">
      {isClicked.rate &&
        !isRated && ( //show ratings modal
          <div
            id="modal"
            className="flex absolute justify-center w-full h-screen"
          >
            <div className="fixed top-20 w-11/12 h-3/4 m-5 border-1 border-black rounded-3xl bg-white drop-shadow-2xl">
              <div className="flex justify-end items-center">
                <button
                  type="buttons"
                  onClick={() => handleClick("rate")}
                  style={{ color: currentColor, borderRadius: "50%" }}
                  className="text-2xl p-3
            hover:bg-light-gray hover:dark:bg-main-dark-bg"
                >
                  <MdOutlineCancel />
                </button>
              </div>
              <div className="flex justify-center text-xl mt-5">
                How did we do?
              </div>

              <div className="flex justify-center mt-5 text-gray-200 ">
                <IconContext.Provider
                  value={{
                    className:
                      "bg-white text-6xl min-[400px]:text-7xl hover:text-yellow-300 dropshadow-2xl",
                  }}
                >
                  {[...Array(5)].map((star, index) => {
                    index += 1;
                    return (
                      <div key={index}>
                        <button
                          key={index}
                          type="button"
                          className={
                            index <= (rating || hover)
                              ? "text-yellow-300"
                              : "text-gray-200"
                          }
                          onClick={() => setRating(index)}
                          onMouseEnter={() => setHover(index)}
                          onMouseLeave={() => setHover(rating)}
                        >
                          <RiStarSFill className="pointer-events-none" />
                        </button>
                      </div>
                    );
                  })}
                </IconContext.Provider>
              </div>

              <div className="flex justify-center w-full mt-5 ">
                <form className="w-80">
                  <div>
                    <label className="flex text-lg m-2" htmlFor="rate">
                      What can we improve next time?
                    </label>
                    <div className="flex justify-center m-2">
                      <textarea
                        className="h-40 w-full p-2 text-base cursor-pointer text-justify border-1 border-black"
                        id="rate"
                        type="text"
                        placeholder="Feedback (Optional)"
                        onChange={handleChange}
                        maxLength={maxChars}
                      ></textarea>
                    </div>
                  </div>
                  <div className="flex justify-end mr-2">{charCount}</div>
                  <div className="flex justify-center my-5">
                    <Button
                      color="white"
                      bgColor="Black"
                      text="Rate"
                      borderRadius="10px"
                      width="full"
                      size="base w-32"
                      customFunc={handleSubmit}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      {isRated &&
        isClicked.rate && ( //show thank you modal
          <div
            id="modal"
            className="flex absolute justify-center w-full h-screen"
          >
            <div className="fixed top-20 w-11/12 h-3/4 m-5 border-1 border-black rounded-3xl bg-white drop-shadow-2xl">
              <div className="flex justify-end items-center">
                <button
                  type="buttons"
                  onClick={() => handleClick("rate")}
                  style={{ color: currentColor, borderRadius: "50%" }}
                  className="text-2xl p-3
          hover:bg-light-gray hover:dark:bg-main-dark-bg"
                >
                  <MdOutlineCancel />
                </button>
              </div>
              <div className="flex justify-center mt-20 text-3xl text-center font-bold">
                Thank You For Your Feedback!
              </div>
              <div className="flex justify-center mt-5">
                <Button
                  color="white"
                  bgColor="gray"
                  text="Close"
                  borderRadius="10px"
                  width="full"
                  size="base w-32"
                  customFunc={handleClose}
                />
              </div>
            </div>
          </div>
        )}
      {/* Show order Tracker */}
      <div className="flex justify-center text-5xl mt-40">Order Tracker:</div>
      <div className="flex justify-center">
        <div className="flex justify-start mt-10 h-12 w-80% border-2 border-color text-3xl">
          <div
            className={`flex justify-center text-white`}
            style={{ backgroundColor: currentColor, width: progress + "%" }}
          >
            {progress}%
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-5 text-xl">
        Your Order Status: &nbsp;
        <span className="text-green-600"> {orderStatus}</span>
      </div>
      <p className="flex justify-center mt-10">{`Thank you ${firstName}!`}</p>
      <p className="flex justify-center">{`A confirmation email was sent to:`}</p>
      <p className="flex justify-center font-bold">{`${email}.`}</p>
    </section>
  );
};

export default OrderTracker;
