import React from "react";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";

import { useStateContext } from "../contexts/ContextProvider";
import CalculateDistance from "./CalculateDistance.jsx";
import { hotels } from "../data/hotel-data.js";

const AutoComplete = ({ placeholder }) => {
  const { latitude, longitude, setUsersHotel, sortedHotels } =
    useStateContext();

  const handleChange = (e) => {
    if (e.itemData) {
      setUsersHotel(e.itemData);
    } else {
      setUsersHotel([]);
    }
  };

  const customItem = (props) => {
    return (
      <div className="flex bold m-2">
        <img
          className="rounded full h-24 w-24 object-cover"
          src={props.image}
          alt={props.name}
        />
        <div className="ml-5">
          <div className="text-wrap indent-0 text-xl font-bold ">
            {props.name}
          </div>
          <div className="flex indent-0 text-lg">
            {props.street_address + ", " + props.city + ", " + props.state}
          </div>
          <div className="flex indent-0 text-lg text-red-500">
            {latitude && longitude ? props.distance + " miles away" : ""}
          </div>
        </div>
      </div>
    );
  };

  return (
    <AutoCompleteComponent
      suggestionCount="100"
      dataSource={sortedHotels ? sortedHotels : hotels}
      placeholder={placeholder}
      itemTemplate={customItem}
      fields={{ key: "id", value: "street_address", text: "street_address" }}
      change={handleChange}
    ></AutoCompleteComponent>
  );
};

export default AutoComplete;
