import React from "react";

const Button = ({ size, text, color, bgColor, borderRadius, customFunc }) => {
  return (
    <button
      type="button"
      style={{ backgroundColor: bgColor, color, borderRadius }}
      className={`text-${size} p-3 hover:drop-shadow-xl`}
      onClick={() => customFunc()}
    >
      {text}
    </button>
  );
};

export default Button;
