import React from "react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";

import { useStateContext } from "../contexts/ContextProvider";
import CalculateDistance from "./CalculateDistance.jsx";

const Dropdown = ({ placeholder }) => {
  const { latitude, longitude, setUsersHotel, sortedHotels } =
    useStateContext();

  const handleChange = (e) => {
    setUsersHotel(e.itemData);
    //console.log("Hotel: ", e.itemData);
  };

  const customItem = (props) => {
    return (
      <div className="flex bold m-2">
        <img
          className="rounded full h-28 w-24 object-cover"
          src={props.image}
          alt={props.name}
        />
        <div className="ml-5">
          <div className="text-wrap indent-0 text-xl font-bold ">
            {props.name}
          </div>
          <div className="flex indent-0 text-lg">
            {props.street_address + ", " + props.city + ", " + props.state}
          </div>
          <div className="flex indent-0 text-sm text-red-500">
            {latitude && longitude ? props.distance + " miles away" : ""}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <DropDownListComponent
        suggestionCount="100"
        width="374px"
        dataSource={sortedHotels.slice(0, 20)}
        placeholder={placeholder}
        itemTemplate={customItem}
        fields={{ key: "id", value: "street_address", text: "street_address" }}
        change={handleChange}
      ></DropDownListComponent>
    </div>
  );
};

export default Dropdown;
