import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { Navbar, Sidebar, ThemeSettings } from "./components";
import {
  Return,
  CheckoutForm,
  Menu,
  Dashboard,
  Orders,
  Calendar,
  Employees,
  Stacked,
  Pyramid,
  Customers,
  Kanban,
  Area,
  Bar,
  Pie,
  Line,
  Financial,
  ColorMapping,
  ColorPicker,
  Editor,
  LandingPage,
  Order,
  OrderStatus,
} from "./pages";

import { useStateContext } from "./contexts/ContextProvider";

import "./App.css";

const App = () => {
  const {
    activeMenu,
    themeSettings,
    setThemeSettings,
    currentColor,
    currentMode,
    adminMode,
  } = useStateContext();

  const location = useLocation();
  const hideComponent =
    location.pathname === "/" || location.pathname === "/order";

  return (
    <div
      className={
        currentMode === "Dark"
          ? "dark flex justify-center bg-main-dark-bg"
          : "flex justify-center bg-main-bg"
      }
    >
      <div className="flex w-full relative dark:bg-main-dark-bg">
        {adminMode ? (
          <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
            <TooltipComponent content="Settings" position="Top">
              <button
                type="button"
                className="text-3xl p3 over:drop-shadow-xl hover:bg-light-gray text-white"
                style={{ background: currentColor, borderRadius: "50%" }}
                onClick={() => setThemeSettings(true)}
              >
                <FiSettings />
              </button>
            </TooltipComponent>
          </div>
        ) : (
          ""
        )}
        {activeMenu ? (
          <div
            className={`w-72 ${
              adminMode ? "relative" : "fixed"
            } top-0 left-0 dark:bg-secondary-dark-bg bg-white z-10 mt-10`}
          >
            <Sidebar />
          </div>
        ) : (
          <div className="w-0 bg-main-bg dark:bg-secondary-dark-bg">
            <Sidebar />
          </div>
        )}
        <div
          className={` dark:bg-main-dark-bg bg-main-bg
              min-h-screen w-full
              ${activeMenu ? " " : "flex-2 "}`}
        >
          <div
            className={`flex fixed top-0 bg-main-bg dark:bg-main-dark-bg navbar ${
              activeMenu && adminMode ? "w-5/6" : "w-full"
            } z-50`}
          >
            {!hideComponent && <Navbar />}
          </div>
          <div>
            {themeSettings && <ThemeSettings />}

            <Routes>
              {/* Pages */}
              <Route path="/" element={<Order />} />
              <Route path="/order" element={<Order />} />
              {!adminMode && <Route path="/menu" element={<Menu />} />}
              <Route path="/checkout" element={<CheckoutForm />} />
              <Route path="/return" element={<Return />} />
              <Route path="/order-status" element={<OrderStatus />} />
              <Route path="*" element={<Navigate to="/" replace />} />

              {adminMode ? (
                <>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/orders" element={<Orders />} />
                  <Route path="/employees" element={<Employees />} />
                  <Route path="/customers" element={<Customers />} />
                  {/* Apps */}
                  <Route path="/kanban" element={<Kanban />} />
                  <Route path="/editor" element={<Editor />} />
                  <Route path="/calendar" element={<Calendar />} />
                  <Route path="/color-picker" element={<ColorPicker />} />
                  {/* Charts */}
                  <Route path="/line" element={<Line />} />
                  <Route path="/area" element={<Area />} />
                  <Route path="/bar" element={<Bar />} />
                  <Route path="/pie" element={<Pie />} />
                  <Route path="/financial" element={<Financial />} />
                  <Route path="/color-mapping" element={<ColorMapping />} />
                  <Route path="/pyramid" element={<Pyramid />} />
                  <Route path="/stacked" element={<Stacked />} />
                </>
              ) : (
                ""
              )}
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
