import React from "react";
import { NavLink, Link } from "react-router-dom";
import { SiShopware } from "react-icons/si";

import { links } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";

const Sidebar = () => {
  const { activeMenu, setActiveMenu, screenSize, currentColor, adminMode } =
    useStateContext();

  const handleCloseSideBar = () => {
    if (activeMenu && screenSize <= 950) {
      setActiveMenu(false);
    }
  };

  const activeLink =
    "flex items-center gap-5 pl-5 pt-3 pb-2.5 rounded-lg text-white text-md m-2";
  const normalLink =
    "flex items-center gap-5 pl-5 pt-3 pb-2.5 pr-20 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray";

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto mt-10 pb-10 ">
      {activeMenu && (
        <>
          <div className="flex justify-between mt-5 text-3xl">
            {adminMode && (
              <div>
                <Link
                  to
                  onClick={handleCloseSideBar}
                  className="flex items-center gap-3 ml-5
              mt-2 text-3xl font-extrabold 
              tracking-tight dark:text-white
              text-slate-900"
                >
                  {/* <SiShopware style={{ color: currentColor }} />{" "} */}
                  <span className="ml-10 mb-5" style={{ color: currentColor }}>
                    Snappily
                  </span>
                </Link>
              </div>
            )}
          </div>
          <div className="mt-5 text-2xl">
            {links.map((item) => (
              <div
                key={item.title}
                hidden={adminMode === item.adminOnly ? false : true}
              >
                <p
                  className="text-gray-400 dark:text-gray-400 m-3
                 mt-4 uppercase"
                >
                  {item.title}
                </p>
                {item.links.flatMap((Link) => (
                  <NavLink
                    to={`/${Link.navigate}`}
                    key={Link.name}
                    onClick={handleCloseSideBar}
                    style={({ isActive }) => ({
                      backgroundColor: isActive ? currentColor : "",
                    })}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    {Link.icon}
                    <span className="capitalize">{Link.name}</span>
                  </NavLink>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
