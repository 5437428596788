import { hotels } from "../hotel-data";

export const sortHotels = (lat1, long1) => {
  const hotelsWithDistance = [];
  hotels.forEach((item) => {
    const distance = calcDistance(
      lat1,
      long1,
      item.location.lat,
      item.location.long
    );
    hotelsWithDistance.push({
      id: item.id,
      name: item.name,
      street_address: item.street_address,
      city: item.city,
      state: item.state,
      image: item.image,
      location: item.location,
      distance: distance,
    });
  });
  const newlySortedHotels = hotelsWithDistance.sort((a, b) => {
    return a.distance - b.distance;
  });
  return newlySortedHotels;
};

export const calcDistance = (lat1, long1, lat2, long2) => {
  const toRadian = (n) => (n * Math.PI) / 180;
  let R = 3959; //  6371 km or 3959 miles
  let x1 = lat2 - lat1;
  let dLat = toRadian(x1);
  let x2 = long2 - long1;
  let dLong = toRadian(x2);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadian(lat1)) *
      Math.cos(toRadian(lat2)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;
  return d.toFixed(1);
};
