import React from "react";
import { BsCurrencyDollar } from "react-icons/bs";
import { GoDotFill } from "react-icons/go";
import { Stacked, Pie, Button, SparkLine } from "../components";
import { earningData, SparklineAreaData, ecompieChartDat } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import { LinearGradient } from "@syncfusion/ej2/circulargauge";

const Dashboard = () => {
  const { currentColor } = useStateContext();

  return (
    <div className="mt-20">
      <div className="flex flex-wrap justify-center">
        <div
          className="flew flex-wrap bg-white dark:text-gray-200 dark:bg-secondary-dark-bg 
        h-44 rounded-xl w-full p-8 pt-9 m-3 bg-hero-background bg-cover bg-center"
          // style={{
          //   backgroundImage: `linear-gradient(to right, transparent, ${currentColor})`,
          // }}
        >
          <div className="flex justify-between items-center">
            <div>
              <p className="font-bold text-xl text-black-400">Earnings</p>
              <p className="text-2xl font-bold">$63,907.53</p>
            </div>
          </div>
          <div className="mt-4">
            <Button
              color="white"
              bgColor={currentColor}
              text="Download"
              borderRadius="10px"
              size="md"
              customFunc={() => console.log("Download Earnings Data")}
            />
          </div>
        </div>
        <div className="flex w-full flex-wrap m-3 justify-center gap-2 lg:gap-10 items-center ">
          {earningData.map((item) => (
            <div
              key={item.title}
              className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg sm:w-56
              p-4 pt-9 rounded-2xl"
            >
              <button
                type="buttons"
                style={{
                  color: item.iconColor,
                  backgroundColor: item.iconBg,
                }}
                className="text-2xl opacity-0.9
                rounded-full p-4 hover:drop-shadow-xl"
              >
                {item.icon}
              </button>
              <p className="mt-3">
                <span className="text-lg font-semibold">${item.amount}</span>
                <span className={`text-sm text-${item.pcColor} ml-2`}>
                  {item.percentage}
                </span>
              </p>
              <p className="text-sm text-gray-400 mt-1">{item.title}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="flex  flex-wrap justify-center gap-10">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-3 p-4 rounded-2xl md:w-780">
          <div className="flex justify-between m-4">
            <p className="font-semibold text-xl">Revenue Updates</p>
            <div className="flex item-center gap-4">
              <p className="flex items-center gap-2 dark:text-light-gray-600 hover:drop-shadow-xl">
                <span>
                  <GoDotFill />
                </span>
                <span>Expense</span>
              </p>
              <p className="flex items-center gap-2 text-green-400 hover:drop-shadow-xl">
                <span>
                  <GoDotFill />
                </span>
                <span>Budget</span>
              </p>
            </div>
          </div>
          <div className="mt-10 flex gap-10 flex-wrap justify-center">
            <div className=" border-color sm:border-r-1  m-4 sm:pr-10">
              <div>
                <p>
                  <span className="text-3xl font-semibold">$93,000.34</span>
                  <span
                    className="p-1.5 hover:drop-shadow-xl cursor:pointer 
                  rounded-full text-white bg-green-400 ml-3 text-xl"
                  >
                    23%
                  </span>
                </p>
                <p className="text-gray-500 mt-1">Budget</p>
              </div>
              <div className="mt-8">
                <p>
                  <span className="text-3xl font-semibold">$3,000.34</span>
                </p>
                <p className="text-gray-500 mt-1">Expense</p>
              </div>
              <div className="mt-5">
                <SparkLine
                  dataPointColor={currentColor}
                  id="line-sparkline"
                  type="Line"
                  height="80px"
                  width="250px"
                  data={SparklineAreaData}
                  lineColor="black"
                />
              </div>
              <div className="mt-10">
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Download Report"
                  borderRadius="10px"
                  customFunc={() => console.log("Download Revenue Report")}
                />
              </div>
            </div>
            <div>
              <Stacked width="320px" height="360px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
