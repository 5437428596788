import React from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import { testMenu } from "../data/test-menu-data.js";
import { liveMenu } from "../data/live-menu-data.js";
import { Button } from "../components";
import AccordianMenu from "../components/AccordianMenu";

let menuItems;
if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
  menuItems = liveMenu;
} else {
  menuItems = testMenu;
}
//console.log("menu: ", menuItems);

const items = [...menuItems];

const Menu = () => {
  const {
    setActiveMenu,
    currentColor,
    cart,
    handleClick,
    usersHotel,
    roomNumber,
    errorAnimation,
  } = useStateContext();

  const navigate = useNavigate();

  const addAddress = () => {
    navigate("/order");
    setActiveMenu(false);
  };

  const cartItemsTotal = cart.reduce((tot, num) => tot + num.qty, 0);

  return (
    <>
      {usersHotel.length !== 0 ? (
        <div className="flex justify-center">
          <div className="w-96 m-2 mt-28 mb-5 p-2 bg-white drop-shadow-2xl rounded-3xl relative">
            <div className="flex justify-center ml-2">Delivering To:</div>
            <div className="flex bold m-2">
              <img
                className="rounded full h-28 w-24 mt-2 object-cover"
                src={usersHotel.image}
                alt={usersHotel.name}
              />
              <div className="ml-5">
                <div
                  className="text-wrap indent-0 text-xl font-bold hover:text-blue-500 hover:cursor-pointer"
                  onClick={addAddress}
                >
                  {usersHotel.name}
                </div>
                <div className="flex indent-0 text-lg">
                  {usersHotel.street_address}
                </div>
                <div className="flex indent-0 text-lg">
                  {usersHotel.city + ", " + usersHotel.state}
                </div>
                <div className="flex indent-0 text-lg">
                  {"Rm#: " + roomNumber}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center">
          <div className="flex w-96 justify-center gap-5 items-center mx-10 p-5 mt-24 mb-5 drop-shadow-2xl bg-white rounded-3xl relative">
            <div className={`flex ml-2 pr-5 ${errorAnimation}`}>
              Add your delivery location:
            </div>
            <div className="flex">
              <Button
                color="white"
                bgColor="Black"
                text="Set Address"
                borderRadius="10px"
                width="full"
                size="base w-32"
                customFunc={addAddress}
              />
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-center drop-shadow-3xl mb-5">
        <div className="w-96 p-5 bg-white drop-shadow-2xl rounded-3xl">
          <div>Menu</div>
          <div className="flex justify-center w-full">
            <AccordianMenu items={items} />
          </div>
        </div>
      </div>
      {cart.length !== 0 && (
        <div className="flex justify-center drop-shadow-3xl">
          <div className="w-96 mb-5">
            <div className="flex justify-center">
              <Button
                size="2xl"
                color="white"
                bgColor={currentColor}
                text={`Cart (${
                  cartItemsTotal >> 1
                    ? `${cartItemsTotal} Items)`
                    : `${cartItemsTotal} Item)`
                }`}
                borderRadius="10px"
                width="full"
                customFunc={() => handleClick("cart")}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Menu;
