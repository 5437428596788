import React from "react";

import MenuCard from "./MenuCard";

const AccordianMenu = ({ items }) => {
  const breakfast = items.filter((item) => item.category === "breakfast");
  const lunch = items.filter((item) => item.category === "lunch");
  const dinner = items.filter((item) => item.category === "dinner");
  const sides = items.filter((item) => item.category === "sides");
  const drinks = items.filter((item) => item.category === "drinks");

  return (
    <div className="max-w-3xl w-full">
      {/* Breakfast Menu */}
      {breakfast.length !== 0 && (
        <MenuCard items={breakfast} category="breakfast" />
      )}
      {/* Lunch Menu */}
      {lunch.length !== 0 && <MenuCard items={lunch} category="lunch" />}
      {/* Dinner */}
      {dinner.length !== 0 && <MenuCard items={dinner} category="dinner" />}
      {/* Sides Menu */}
      {sides.length !== 0 && <MenuCard items={sides} category="sides" />}
      {/* Drinks Menu */}
      {drinks.length !== 0 && <MenuCard items={drinks} category="drinks" />}
    </div>
  );
};

export default AccordianMenu;
