import React, { useEffect, useState } from "react";
import axios from "axios";

const BACKEND_API = process.env.REACT_APP_BACKEND_API;

const OrderStatus = () => {
  const [currentStatus, setCurrentStatus] = useState(null);
  const [repeat, setRepeat] = useState(false);
  const [status, setStatus] = useState("");
  const [error, setError] = useState("NONE");

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const session_id = urlParams.get("session_id");
  const newStatus = urlParams.get("status");

  useEffect(() => {
    axios
      .get(`${BACKEND_API}/order-status/${session_id}`)
      .then((response) => {
        setCurrentStatus(response.data.status.toLowerCase());
      })
      .catch((error) => {
        setError(
          error.response.data.message
            ? error.response.data.message
            : error.message
        );
      });
  }, []);

  useEffect(() => {
    if (currentStatus === null) {
      return;
    } else {
      if (currentStatus === newStatus || currentStatus === "delivered") {
        setRepeat(true);
      } else {
        axios
          .put(`${BACKEND_API}/order-status/${session_id}`, {
            session_id: session_id,
            status: newStatus,
          })
          .then((response) => {
            setStatus(response.data.status);
          })
          .catch((error) => {
            setError(
              error.response.data.message
                ? error.response.data.message
                : error.message
            );
          });
      }
    }
  }, [currentStatus]);

  return (
    <div className="ml-20">
      <div className="mt-24 text-xl font-bold">Order Status Page</div>
      {repeat ? (
        <>
          <div className="text-red-500 text-xl">Repeat Request!</div>
          {currentStatus === "delivered" ? (
            <div className="text-red-500 text-xl">
              (Order Already Delivered)
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <div className="text-xl">
          Order has been marked as:{" "}
          <span className="text-green-500 text-xl items-center">
            {status.toUpperCase()}
          </span>
        </div>
      )}
      {error === "NONE" ? (
        ""
      ) : (
        <div className="text-red-500">ERROR: {error.toUpperCase()}</div>
      )}
    </div>
  );
};

export default OrderStatus;
