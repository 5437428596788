import hotel1 from "./hotel-photos/hotel-01.jpg";
import hotel2 from "./hotel-photos/hotel-02.jpg";
import hotel3 from "./hotel-photos/hotel-03.jpg";
import hotel4 from "./hotel-photos/hotel-04.jpg";
import hotel5 from "./hotel-photos/hotel-05.jpg";
import hotel6 from "./hotel-photos/hotel-06.jpg";
import hotel7 from "./hotel-photos/hotel-07.jpg";
import hotel8 from "./hotel-photos/hotel-08.jpg";
import hotel9 from "./hotel-photos/hotel-09.jpg";
import hotel10 from "./hotel-photos/hotel-10.jpg";
import hotel11 from "./hotel-photos/hotel-11.jpg";
import hotel12 from "./hotel-photos/hotel-12.jpg";
import hotel13 from "./hotel-photos/hotel-13.jpg";
import hotel14 from "./hotel-photos/hotel-14.jpg";
import hotel15 from "./hotel-photos/hotel-15.jpg";
import hotel16 from "./hotel-photos/hotel-16.jpg";
import hotel17 from "./hotel-photos/hotel-17.jpg";
import hotel18 from "./hotel-photos/hotel-18.jpg";
import hotel19 from "./hotel-photos/hotel-19.jpg";
import hotel20 from "./hotel-photos/hotel-20.jpg";
import hotel21 from "./hotel-photos/hotel-21.jpg";
import hotel22 from "./hotel-photos/hotel-22.jpg";
import hotel23 from "./hotel-photos/hotel-23.jpg";
import hotel24 from "./hotel-photos/hotel-24.jpg";
import hotel25 from "./hotel-photos/hotel-25.jpg";
import hotel26 from "./hotel-photos/hotel-26.jpg";
import hotel27 from "./hotel-photos/hotel-27.jpg";
import hotel28 from "./hotel-photos/hotel-28.jpg";
import hotel29 from "./hotel-photos/hotel-29.jpg";
import hotel30 from "./hotel-photos/hotel-30.jpg";
import hotel31 from "./hotel-photos/hotel-31.jpg";
import hotel32 from "./hotel-photos/hotel-32.jpg";
import hotel33 from "./hotel-photos/hotel-33.jpg";
import hotel34 from "./hotel-photos/hotel-34.jpg";
import hotel35 from "./hotel-photos/hotel-35.jpg";
import hotel36 from "./hotel-photos/hotel-36.jpg";
import hotel37 from "./hotel-photos/hotel-37.jpg";
import hotel38 from "./hotel-photos/hotel-38.jpg";
import hotel39 from "./hotel-photos/hotel-39.jpg";
import hotel40 from "./hotel-photos/hotel-40.jpg";
import hotel41 from "./hotel-photos/hotel-41.jpg";
import hotel42 from "./hotel-photos/hotel-42.jpg";
import hotel43 from "./hotel-photos/hotel-43.jpg";
import hotel44 from "./hotel-photos/hotel-44.jpg";
import hotel45 from "./hotel-photos/hotel-45.jpg";
import hotel46 from "./hotel-photos/hotel-46.jpg";
import hotel47 from "./hotel-photos/hotel-47.jpg";
import hotel48 from "./hotel-photos/hotel-48.jpg";
import hotel49 from "./hotel-photos/hotel-49.jpg";
import hotel50 from "./hotel-photos/hotel-40.jpg";
import hotel51 from "./hotel-photos/hotel-51.jpg";
import hotel52 from "./hotel-photos/hotel-52.jpg";
import hotel53 from "./hotel-photos/hotel-53.jpg";
import hotel54 from "./hotel-photos/hotel-54.jpg";
import hotel55 from "./hotel-photos/hotel-55.jpg";
import hotel56 from "./hotel-photos/hotel-56.jpg";
import hotel57 from "./hotel-photos/hotel-57.jpg";
import hotel58 from "./hotel-photos/hotel-58.jpg";
import hotel59 from "./hotel-photos/hotel-59.jpg";
import hotel60 from "./hotel-photos/hotel-60.jpg";
import hotel61 from "./hotel-photos/hotel-61.jpg";
import hotel62 from "./hotel-photos/hotel-62.jpg";
import hotel63 from "./hotel-photos/hotel-63.jpg";
import hotel64 from "./hotel-photos/hotel-64.jpg";
import hotel65 from "./hotel-photos/hotel-65.jpg";
import hotel66 from "./hotel-photos/hotel-66.jpg";
import hotel67 from "./hotel-photos/hotel-67.jpg";
import hotel68 from "./hotel-photos/hotel-68.jpg";
import hotel69 from "./hotel-photos/hotel-69.jpg";
import hotel70 from "./hotel-photos/hotel-70.jpg";
import hotel71 from "./hotel-photos/hotel-71.jpg";
import hotel72 from "./hotel-photos/hotel-72.jpg";
import hotel73 from "./hotel-photos/hotel-73.jpg";
import hotel74 from "./hotel-photos/hotel-74.jpg";
import hotel75 from "./hotel-photos/hotel-75.jpg";
import hotel76 from "./hotel-photos/hotel-76.jpg";
import hotel77 from "./hotel-photos/hotel-77.jpg";
import hotel78 from "./hotel-photos/hotel-78.jpg";
import hotel79 from "./hotel-photos/hotel-79.jpg";
import hotel80 from "./hotel-photos/hotel-80.jpg";
import hotel81 from "./hotel-photos/hotel-81.jpg";
import hotel82 from "./hotel-photos/hotel-82.jpg";
import hotel83 from "./hotel-photos/hotel-83.jpg";
import hotel84 from "./hotel-photos/hotel-84.jpg";
import hotel85 from "./hotel-photos/hotel-85.jpg";
import hotel86 from "./hotel-photos/hotel-86.jpg";
import hotel87 from "./hotel-photos/hotel-87.jpg";
import hotel88 from "./hotel-photos/hotel-88.jpg";
import hotel89 from "./hotel-photos/hotel-89.jpg";
import hotel90 from "./hotel-photos/hotel-90.jpg";
import hotel91 from "./hotel-photos/hotel-91.jpg";
import hotel92 from "./hotel-photos/hotel-92.jpg";
import hotel93 from "./hotel-photos/hotel-93.jpg";
import hotel94 from "./hotel-photos/hotel-94.jpg";
import hotel95 from "./hotel-photos/hotel-95.jpg";
import hotel96 from "./hotel-photos/hotel-96.jpg";
import hotel97 from "./hotel-photos/hotel-97.jpg";
import hotel98 from "./hotel-photos/hotel-98.jpg";
import hotel99 from "./hotel-photos/hotel-99.jpg";
import hotel100 from "./hotel-photos/hotel-100.jpg";
import hotel101 from "./hotel-photos/hotel-101.jpg";
import hotel102 from "./hotel-photos/hotel-102.jpg";
import hotel666 from "./hotel-photos/hotel-666.jpg";
import hotel999 from "./hotel-photos/hotel-999.jpg";

export const hotels = [
  {
    id: 1,
    name: "Hampton Inn Manhattan Grand Central",
    street_address: "231 East 43rd Street",
    city: "New York",
    state: "NY",
    image: hotel1,
    location: { lat: 40.7509857, long: -73.9721619 },
  },
  {
    id: 2,
    name: "Motto by Hilton New York City Chelsea",
    street_address: "113 West 24th Street",
    city: "New York",
    state: "NY",
    image: hotel2,
    location: { lat: 40.7440168, long: -73.9954523 },
  },
  {
    id: 3,
    name: "Hampton Inn Manhattan/Times Square South",
    street_address: "337 West 39th Street",
    city: "New York",
    state: "NY",
    image: hotel3,
    location: { lat: 40.7562403, long: -73.9954374 },
  },
  {
    id: 4,
    name: "DoubleTree by Hilton New York City - Chelsea",
    street_address: "128 West 29th Street",
    city: "New York",
    state: "NY",
    image: hotel4,
    location: { lat: 40.7470152, long: -73.9915344 },
  },
  {
    id: 5,
    name: "Hilton Garden Inn New York/Manhatten-Chelsea",
    street_address: "121 West 28th Street",
    city: "New York",
    state: "NY",
    image: hotel5,
    location: { lat: 40.746723, long: -73.9940042 },
  },
  {
    id: 6,
    name: "Motto by Hilton New York City Times Square",
    street_address: "150 A West 48th Street",
    city: "New York",
    state: "NY",
    image: hotel6,
    location: { lat: 40.7593453, long: -73.9848585 },
  },
  {
    id: 7,
    name: "Hampton Inn Manhattan-Madison Square Garden Area",
    street_address: "116 West 31st Street",
    city: "New York",
    state: "NY",
    image: hotel7,
    location: { lat: 40.7480695, long: -73.9927583 },
  },
  {
    id: 8,
    name: "Hilton New York Fashion District",
    street_address: "152 West 26th Street",
    city: "New York",
    state: "NY",
    image: hotel8,
    location: { lat: 40.7455351, long: -73.9963567 },
  },
  {
    id: 9,
    name: "Hilton Garden Inn New York/Manhattan-Midtown East",
    street_address: "206 East 52nd Street",
    city: "New York",
    state: "NY",
    image: hotel9,
    location: { lat: 40.7566332, long: -73.9720174 },
  },
  {
    id: 10,
    name: "Hampton Inn Manhattan/Times Square Central",
    street_address: "220 West 41st Street",
    city: "New York",
    state: "NY",
    image: hotel10,
    location: { lat: 40.7556359, long: -73.9912344 },
  },
  {
    id: 11,
    name: "DoubleTree by Hilton New York Midtown Fifth Ave",
    street_address: "25 West 51st Street",
    city: "New York",
    state: "NY",
    image: hotel11,
    location: { lat: 40.7600348, long: -73.9807939 },
  },
  {
    id: 12,
    name: "The Draper New York, Tapestry Collection by Hilton",
    street_address: "4-6 West 37th Street",
    city: "New York",
    state: "NY",
    image: hotel12,
    location: { lat: 40.7503734, long: -73.9862492 },
  },
  {
    id: 13,
    name: "DoubleTree by New York Times Square South",
    street_address: "525 8th Avenue",
    city: "New York",
    state: "NY",
    image: hotel13,
    location: { lat: 40.7538276, long: -73.9951091 },
  },
  {
    id: 14,
    name: "Hilton Garden Inn New York/West 35th Street",
    street_address: "63 West 35th Street",
    city: "New York",
    state: "NY",
    image: hotel14,
    location: { lat: 40.7504057, long: -73.989055 },
  },
  {
    id: 15,
    name: "Hamption Inn Manhattan-Chelsea",
    street_address: "108 West 24th Street",
    city: "New York",
    state: "NY",
    image: hotel15,
    location: { lat: 40.7436548, long: -73.9956198 },
  },
  {
    id: 16,
    name: "Millennium Hilton New York One UN Plaza",
    street_address: "One UN PLaza",
    city: "New York",
    state: "NY",
    image: hotel16,
    location: { lat: 40.7505732, long: -73.97205 },
  },
  {
    id: 17,
    name: "Distrikt Hotel New York City, Tapestry Collection by Hilton",
    street_address: "342 West 40th Street",
    city: "New York",
    state: "NY",
    image: hotel17,
    location: { lat: 40.7566302, long: -73.9954314 },
  },
  {
    id: 18,
    name: "Hampton Inn by Hilton New York Time Square",
    street_address: "150 West 48th Street",
    city: "New York",
    state: "NY",
    image: hotel18,
    location: { lat: 40.7595001, long: -73.9862811 },
  },
  {
    id: 19,
    name: "DoubleTree by Hilton Hotel New York Times Square West",
    street_address: "350 West 40th Street",
    city: "New York",
    state: "NY",
    image: hotel19,
    location: { lat: 40.756753, long: -73.9957527 },
  },
  {
    id: 20,
    name: "Hilton Garden Inn New York Times Square South",
    street_address: "326 West 37th Street",
    city: "New York",
    state: "NY",
    image: hotel20,
    location: { lat: 40.754486, long: -73.9961914 },
  },
  {
    id: 21,
    name: "Martinique New York on Broadway, Curio Collection by Hilton",
    street_address: "49 West 32nd Street",
    city: "New York",
    state: "NY",
    image: hotel21,
    location: { lat: 40.7483485, long: -73.9903892 },
  },
  {
    id: 22,
    name: "Hilton Garden Inn New York/Time Square Central",
    street_address: "136 West 42nd Street",
    city: "New York",
    state: "NY",
    image: hotel22,
    location: { lat: 40.7553167, long: -73.9882396 },
  },
  {
    id: 23,
    name: "New York Hilton Midtown",
    street_address: "1335 Avenue of the Americas",
    city: "New York",
    state: "NY",
    image: hotel23,
    location: { lat: 40.762455, long: -73.9822907 },
  },
  {
    id: 24,
    name: "Hampton Inn Manhattan-35th/Empire State Bldg",
    street_address: "59 West 35th Street",
    city: "New York",
    state: "NY",
    image: hotel24,
    location: { lat: 40.7502872, long: -73.9889714 },
  },
  {
    id: 25,
    name: "Home2 Suites by Hilton New York Times Square",
    street_address: "150 West 48th Street",
    city: "New York",
    state: "NY",
    image: hotel25,
    location: { lat: 40.7595001, long: -73.9862811 },
  },
  {
    id: 26,
    name: "Hilton New York Time Square",
    street_address: "234 West 42th Street",
    city: "New York",
    state: "NY",
    image: hotel26,
    location: { lat: 40.7566328, long: -73.9913717 },
  },
  {
    id: 27,
    name: "Hilton Garden Inn New York/Midtown Park Ave",
    street_address: "45 East 33rd Street",
    city: "New York",
    state: "NY",
    image: hotel27,
    location: { lat: 40.7467409, long: -73.9849211 },
  },
  {
    id: 28,
    name: "Hilton Garden Inn New York Times Square North",
    street_address: "30 West 46th Street",
    city: "New York",
    state: "NY",
    image: hotel28,
    location: { lat: 40.7564318, long: -73.9832485 },
  },
  {
    id: 29,
    name: "Embassy Suites by Hilton New York Manhattan Times Square",
    street_address: "60 West 37th Street",
    city: "New York",
    state: "NY",
    image: hotel29,
    location: { lat: 40.751177, long: -73.9885012 },
  },
  {
    id: 30,
    name: "Hilton Garden Inn New York/Central Park South-Midtown West",
    street_address: "237 West 54th Street",
    city: "New York",
    state: "NY",
    image: hotel30,
    location: { lat: 40.76456, long: -73.9857105 },
  },
  {
    id: 31,
    name: "Homewood Suites by Hilton New York/Midtown Manhattan Times Square-South",
    street_address: "312 West 37th Street",
    city: "New York",
    state: "NY",
    image: hotel31,
    location: { lat: 40.7543085, long: -73.9956609 },
  },
  {
    id: 32,
    name: "The Bernic Hotel New York City, Tapestry Collection by Hilton",
    street_address: "145 - 147 West 47th Street",
    city: "New York",
    state: "NY",
    image: hotel32,
    location: { lat: 40.758951, long: -73.9859663 },
  },
  {
    id: 33,
    name: "Tempo by Hilton New York Times Square",
    street_address: "1568 Broadway",
    city: "New York",
    state: "NY",
    image: hotel33,
    location: { lat: 40.7589085, long: -73.9869986 },
  },
  {
    id: 34,
    name: "Conrad New York Midtown",
    street_address: "151 West 54th Street",
    city: "New York",
    state: "NY",
    image: hotel34,
    location: { lat: 40.7634381, long: -73.9831164 },
  },
  {
    id: 35,
    name: "Hilton Club The Cetral at 5th New York",
    street_address: "12 East 48th Street",
    city: "New York",
    state: "NY",
    image: hotel35,
    location: { lat: 40.7567431, long: -73.9799129 },
  },
  {
    id: 36,
    name: "Hilton Club The Quin New York",
    street_address: "101 West 57th Street",
    city: "New York",
    state: "NY",
    image: hotel36,
    location: { lat: 40.7646408, long: -73.979821 },
  },
  {
    id: 37,
    name: "Hilton Club West 57th Street New York",
    street_address: "102 West 57th Street",
    city: "New York",
    state: "NY",
    image: hotel37,
    location: { lat: 40.7643688, long: -73.9804868 },
  },
  {
    id: 38,
    name: "Hilton Club New York",
    street_address: "1335 Avenue of the Americas",
    city: "New York",
    state: "NY",
    image: hotel38,
    location: { lat: 40.762455, long: -73.9822907 },
  },
  {
    id: 39,
    name: "Hilton Club The Residences New York",
    street_address: "1335 Avenue of the Americas",
    city: "New York",
    state: "NY",
    image: hotel39,
    location: { lat: 40.762455, long: -73.9822907 },
  },
  {
    id: 40,
    name: "Waldorf Astoria New York",
    street_address: "301 Park Avenue",
    city: "New York",
    state: "NY",
    image: hotel40,
    location: { lat: 40.7564821, long: -73.9759936 },
  },
  {
    id: 41,
    name: "Hilton Garden Inn Sunnyvale",
    street_address: "767 N Mathilda Ave",
    city: "Sunnyvale",
    state: "CA",
    image: hotel41,
    location: { lat: 37.3948434, long: -122.0325277 },
  },
  {
    id: 42,
    name: "Homewood Suites by Hilton Newark-Fremont",
    street_address: "39270 Cedar Blvd",
    city: "Newark",
    state: "CA",
    image: hotel42,
    location: { lat: 37.5233969, long: -122.005105 },
  },
  {
    id: 43,
    name: "Hampton Inn & Suites Mountain View",
    street_address: "390 Moffett Blvd",
    city: "Mountain View",
    state: "CA",
    image: hotel43,
    location: { lat: 37.3988471, long: -122.0783612 },
  },
  {
    id: 44,
    name: "Hilton Garden Inn Palo Alto",
    street_address: "4216 El Camino Real",
    city: "Palo Alto",
    state: "CA",
    image: hotel44,
    location: { lat: 37.4091948, long: -122.1252285 },
  },
  {
    id: 45,
    name: "Hilton Garden Inn San Jose/Milpitas",
    street_address: "30 Ranch Dr",
    city: "Milpitas",
    state: "CA",
    image: hotel45,
    location: { lat: 37.4250316, long: -121.9257246 },
  },
  {
    id: 46,
    name: "DoubleTree By Hilton Hotel Newark",
    street_address: "39900 Balentine Dr",
    city: "Newark",
    state: "CA",
    image: hotel46,
    location: { lat: 37.5220694, long: -121.9932552 },
  },
  {
    id: 47,
    name: "Hilton Garden Inn Fremont Milpitas",
    street_address: "45976 Warm Springs Blvd",
    city: "Fremont",
    state: "CA",
    image: hotel47,
    location: { lat: 37.4946877, long: -121.9340594 },
  },
  {
    id: 48,
    name: "Avatar Hotel Santa Clara, Tapestry Collection by Hilton",
    street_address: "4200 Great America Pkwy",
    city: "Santa Clara",
    state: "CA",
    image: hotel48,
    location: { lat: 37.3915731, long: -121.9797149 },
  },
  {
    id: 49,
    name: "Homewood Suites by Hilton Sunnyvale - Silicon Valley",
    street_address: "830 E El Camino Real",
    city: "Sunnyvale",
    state: "CA",
    image: hotel49,
    location: { lat: 37.3551658, long: -122.0203001 },
  },
  {
    id: 50,
    name: "Juniper Hotel Cupertino, Curio Collection by Hilton",
    street_address: "10050 S De Anza Blvd",
    city: "Curertino",
    state: "CA",
    image: hotel50,
    location: { lat: 37.3217287, long: -122.0341716 },
  },
  {
    id: 51,
    name: "Hampton Inn Milpitas",
    street_address: "215 Barber Ct",
    city: "Milpitas",
    state: "CA",
    image: hotel51,
    location: { lat: 37.4235292, long: -121.9223347 },
  },
  {
    id: 52,
    name: "Homewood Suites by Hilton San Jose North",
    street_address: "4315 N First St",
    city: "San Jose",
    state: "CA",
    image: hotel52,
    location: { lat: 37.4198603, long: -121.96493 },
  },
  {
    id: 53,
    name: "Hampton Inn & Suites San Jose Airport",
    street_address: "2088 N First St",
    city: "San Jose",
    state: "CA",
    image: hotel53,
    location: { lat: 37.3759931, long: -121.9224307 },
  },
  {
    id: 54,
    name: "Hilton Garden Inn San Jose Airport",
    street_address: "101 E Gish Rd",
    city: "San Jose",
    state: "CA",
    image: hotel54,
    location: { lat: 37.362929, long: -121.9105745 },
  },
  {
    id: 55,
    name: "Hampton Inn Fremont",
    street_address: "46500 Landing Pkwy",
    city: "Fremont",
    state: "CA",
    image: hotel55,
    location: { lat: 37.4847301, long: -121.9427141 },
  },
  {
    id: 56,
    name: "Embassy Suites by Hilton Milpitas Silicon Valley",
    street_address: "901 E Calaveras Blvd",
    city: "Milpitas",
    state: "CA",
    image: hotel56,
    location: { lat: 37.4847301, long: -121.9427141 },
  },
  {
    id: 57,
    name: "DoubleTree by Hilton Hotel San Jose",
    street_address: "2050 Gateway Pl",
    city: "San Jose",
    state: "CA",
    image: hotel57,
    location: { lat: 37.3717763, long: -121.9252878 },
  },
  {
    id: 58,
    name: "Hampton Inn San Jose Cherry Ave",
    street_address: "5190 Cherry Ave",
    city: "San Jose",
    state: "CA",
    image: hotel58,
    location: { lat: 37.2570648, long: -121.8734823 },
  },
  {
    id: 59,
    name: "Homewood Suites by Hilton San Jose Airport-Silicon Valley",
    street_address: "10 W Trimble Rd",
    city: "San Jose",
    state: "CA",
    image: hotel59,
    location: { lat: 37.3846167, long: -121.9309419 },
  },
  {
    id: 60,
    name: "Hilton Santa Clara",
    street_address: "4949 Great America Pkwy",
    city: "Santa Clara",
    state: "CA",
    image: hotel60,
    location: { lat: 37.4026316, long: -121.9794517 },
  },
  {
    id: 61,
    name: "Homewood Suites by Hilton Palo Alto",
    street_address: "4329 El Camino Real",
    city: "Palo Alto",
    state: "CA",
    image: hotel61,
    location: { lat: 37.4057975, long: -122.1217446 },
  },
  {
    id: 62,
    name: "Hampton Inn & Suites Sunnyvale Silicon Valley",
    street_address: "861 E El Camino Real",
    city: "Sunnyvale",
    state: "CA",
    image: hotel62,
    location: { lat: 37.3543752, long: -122.0175999 },
  },
  {
    id: 63,
    name: "Hilton Garden Cupertino",
    street_address: "10741 N Wolfe Rd",
    city: "Cupertino",
    state: "CA",
    image: hotel63,
    location: { lat: 37.3334393, long: -122.0174405 },
  },
  {
    id: 64,
    name: "DoubleTree by Hilton Hotel Campbell - Pruneyard Plaza",
    street_address: "1995 S Bascom Ave",
    city: "Campbell",
    state: "CA",
    image: hotel64,
    location: { lat: 37.2887751, long: -121.9361902 },
  },
  {
    id: 65,
    name: "Embassy Suites by Hilton Snata Clara Silicon Valley",
    street_address: "2885 Lakeside Drive",
    city: "Santa Clara",
    state: "CA",
    image: hotel65,
    location: { lat: 37.3839633, long: -121.9812172 },
  },
  {
    id: 66,
    name: "Hayes Mansion San Jose, Curio Collection by Hilton",
    street_address: "200 Edenvale Ave",
    city: "San Jose",
    state: "CA",
    image: hotel66,
    location: { lat: 37.2623117, long: -121.8233506 },
  },
  {
    id: 67,
    name: "Hilton San Jose",
    street_address: "300 Almaden Blvd",
    city: "San Jose",
    state: "CA",
    image: hotel67,
    location: { lat: 37.3292799, long: -121.8915442 },
  },
  {
    id: 68,
    name: "Signa by Hilton San Jose",
    street_address: "170 S. Market Street",
    city: "San Jose",
    state: "CA",
    image: hotel68,
    location: { lat: 37.3331616, long: -121.8913387 },
  },
  {
    id: 69,
    name: "Home2 Suites by Hilton San Jose South",
    street_address: "5952 Silver Creek Valley Rd",
    city: "San Jose",
    state: "CA",
    image: hotel69,
    location: { lat: 37.2586762, long: -121.7871309 },
  },
  {
    id: 70,
    name: "The Inn at Saratoga, Tapestry Collection by Hilton",
    street_address: "20645 Fourth Street",
    city: "Newark",
    state: "CA",
    image: hotel70,
    location: { lat: 37.2578196, long: -122.0371748 },
  },
  {
    id: 71,
    name: "Homewood Suites by Hilton Fremont",
    street_address: "43151 Christy Street",
    city: "Newark",
    state: "CA",
    image: hotel71,
    location: { lat: 37.5147767, long: -121.9982529 },
  },
  {
    id: 72,
    name: "Spark by Hilton San Antonio Airport",
    street_address: "8640 Crownhill Blvd",
    city: "San Antonio",
    state: "TX",
    image: hotel72,
    location: { lat: 29.5148874, long: -98.4645776 },
  },

  {
    id: 73,
    name: "Home2 Suites by Hilton San Antonio Airport, TX",
    street_address: "94 NE Loop 410",
    city: "San Antonio",
    state: "TX",
    image: hotel73,
    location: { lat: 29.5187362, long: -98.4870726 },
  },
  {
    id: 74,
    name: "Hampton Inn & Suites San Antonio-Airport",
    street_address: "8902 Jones Maltsberger Rd",
    city: "San Antonio",
    state: "TX",
    image: hotel74,
    location: { lat: 29.5221462, long: -98.4861419 },
  },
  {
    id: 75,
    name: "Hilton Garden Inn San Antonio Airport",
    street_address: "12828 San Pedro Avenue",
    city: "San Antonio",
    state: "TX",
    image: hotel75,
    location: { lat: 29.5574574, long: -98.4832956 },
  },
  {
    id: 76,
    name: "Homewood Suites by Hilton San Antonio Airport",
    street_address: "8531 Broadway Street",
    city: "San Antonio",
    state: "TX",
    image: hotel76,
    location: { lat: 29.5186955, long: -98.4644405 },
  },
  {
    id: 77,
    name: "Hilton Garden Inn San Antonio Airport South",
    address: "8505 Broadway",
    city: "San Antonio",
    state: "TX",
    image: hotel77,
    location: { lat: 29.5190845, long: -98.4648821 },
  },
  {
    id: 78,
    name: "Hampton Inn & Suites San Antonio/Northeast I-35",
    street_address: "6718 N Interstate 35",
    city: "San Antonio",
    state: "TX",
    image: hotel78,
    location: { lat: 29.4915853, long: -98.4033877 },
  },
  {
    id: 79,
    name: "DoubleTree by Hilton San Antonio Airport",
    street_address: "611 NW Loop 410",
    city: "San Antonio",
    state: "TX",
    image: hotel79,
    location: { lat: 29.5214574, long: -98.5027311 },
  },
  {
    id: 80,
    name: "Embassy Suites by Hilton San Antonio Airport",
    street_address: "10110 US-281",
    city: "San Antonio",
    state: "TX",
    image: hotel80,
    location: { lat: 29.5304081, long: -98.4862384 },
  },
  {
    id: 81,
    name: "Estancia del Norte San Antonio, Tapestry Collection by Hilton",
    address: "37 NE Loop 410 at McCullough",
    city: "San Antonio",
    state: "TX",
    image: hotel81,
    location: { lat: 29.5215844, long: -98.4913392 },
  },
  {
    id: 82,
    name: "DoubleTree by Hilton Nashua",
    street_address: "2 Somerset Pkwy",
    city: "Nashua",
    state: "NH",
    image: hotel82,
    location: { lat: 42.7871098, long: -71.5067215 },
  },
  {
    id: 83,
    name: "Hampton Inn Nashua",
    street_address: "407 Amherst Street",
    city: "Nashua",
    state: "NH",
    image: hotel83,
    location: { lat: 42.7863484, long: -71.5127617 },
  },
  {
    id: 84,
    name: "Homewood Suites by Hilton Manchester/Airport",
    street_address: "1000 Perimeter Rd",
    city: "Manchester",
    state: "NH",
    image: hotel84,
    location: { lat: 42.9362872, long: -71.4467881 },
  },
  {
    id: 85,
    name: "Hampton Inn & Suites Manchester-Bedford",
    street_address: "8 Hawthorne Drive",
    city: "Bedford",
    state: "NH",
    image: hotel85,
    location: { lat: 42.9421162, long: -71.4695969 },
  },
  {
    id: 86,
    name: "Hampton Inn Portsmouth Central",
    street_address: "99 Durgin Lane",
    city: "Portsmouth",
    state: "NH",
    image: hotel86,
    location: { lat: 43.0875362, long: -70.7989874 },
  },
  {
    id: 87,
    name: "Homewood Suites by Hilton Portsmouth",
    street_address: "100 Portsmouth Blvd",
    city: "Portsmouth",
    state: "NH",
    image: hotel87,
    location: { lat: 43.0901832, long: -70.7841289 },
  },
  {
    id: 88,
    name: "Hampton Inn & Suites Portsmouth Downtown",
    street_address: "23 Portwalk Place",
    city: "Portsmouth",
    state: "NH",
    image: hotel88,
    location: { lat: 43.0779631, long: -70.7639263 },
  },
  {
    id: 89,
    name: "Hilton Garden Inn Portsmouth Downtown",
    street_address: "100 High Street",
    city: "Portsmouth",
    state: "NH",
    image: hotel89,
    location: { lat: 43.0780201, long: -70.7619009 },
  },
  {
    id: 90,
    name: "Hampton Inn & Suites Camp Springs/Andrews AFB",
    street_address: "500 Mercedes Blvd",
    city: "Camp Springs",
    state: "MD",
    image: hotel90,
    location: { lat: 38.8214787, long: -76.9175423 },
  },
  {
    id: 91,
    name: "Hilton Garden Inn Washington D.C. Downtown",
    street_address: "815 14th Street N.W.",
    city: "Washington",
    state: "DC",
    image: hotel91,
    location: { lat: 38.9005795, long: -77.0332972 },
  },
  {
    id: 92,
    name: "Waldorf Astoria Washington DC",
    street_address: "1100 Pennsylvania Ave.",
    city: "Washington",
    state: "DC",
    image: hotel92,
    location: { lat: 38.894108, long: -77.0301569 },
  },
  {
    id: 93,
    name: "Embassy Suites by Hilton Washington DC Convention Center",
    street_address: "900 10th Street NW",
    city: "Washington",
    state: "DC",
    image: hotel93,
    location: { lat: 38.9019456, long: -77.0287928 },
  },
  {
    id: 94,
    name: "Hampton Inn & Suites New Haven - South - West Haven",
    street_address: "510 Saw Mill Rd",
    city: "West Haven",
    state: "CT",
    image: hotel94,
    location: { lat: 41.268729, long: -72.9782912 },
  },
  {
    id: 95,
    name: "Homewood Suites by Hilton Orange New Haven",
    street_address: "99 March Hill Rd",
    city: "Orange",
    state: "CT",
    image: hotel95,
    location: { lat: 41.2521153, long: -73.0008678 },
  },
  {
    id: 96,
    name: "DoubleTree by Hilton Alana - Waikiki Beach",
    street_address: "1956 Ala Moana Blvd",
    city: "Honolulu",
    state: "HI",
    image: hotel96,
    location: { lat: 21.2857279, long: -157.8366019 },
  },
  {
    id: 97,
    name: "Hilton Hawaiian Village Waikiki Beach",
    street_address: "2005 Kalia Rd",
    city: "Honolulu",
    state: "HI",
    image: hotel97,
    location: { lat: 21.282395, long: -157.8401099 },
  },
  {
    id: 98,
    name: "Ka La'i Waikiki Beach, LXR Hotels and Resorts",
    street_address: "223 Saratoga Rd",
    city: "Honolulu",
    state: "HI",
    image: hotel98,
    location: { lat: 21.282395, long: -157.8401099 },
  },
  {
    id: 99,
    name: "Embassy Suites by Hilton Waikiki Beach Walk",
    street_address: "201 Beach Walk Street",
    city: "Honolulu",
    state: "HI",
    image: hotel99,
    location: { lat: 21.2793123, long: -157.8342343 },
  },
  {
    id: 100,
    name: "Hilton Garden Inn Waikiki Beach",
    street_address: "2330 Kuhio Ave",
    city: "Honolulu",
    state: "HI",
    image: hotel100,
    location: { lat: 21.2788835, long: -157.8252433 },
  },
  {
    id: 101,
    name: "Hilton Waikiki Beach",
    street_address: "2500 Kuhio Ave",
    city: "Honolulu",
    state: "HI",
    image: hotel101,
    location: { lat: 21.2788951, long: -157.840028 },
  },
  {
    id: 102,
    name: "Hampton Inn & Suites Charlettetown",
    street_address: "300 Capital Drive, Charlettetown",
    city: "Charlettetown",
    state: "PE",
    image: hotel102,
    location: { lat: 46.2618191, long: -63.1662247 },
  },
  {
    id: 666,
    name: "The Overlook Hotel",
    street_address: "27500 E Timberline Road",
    city: "Estes Park",
    state: "CO",
    image: hotel666,
    location: { lat: 45.3309269, long: -121.7114812 },
  },
  {
    id: 999,
    name: "Hotel Marcel New Haven, Tapestry Collection by Hilton",
    street_address: "500 Sargent Dr",
    city: "New Haven",
    state: "CT",
    image: hotel999,
    location: { lat: 41.296387, long: -72.9188813 },
  },
];
