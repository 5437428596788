import React, { useState } from "react";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";

import { Button } from ".";
import { useStateContext } from "../contexts/ContextProvider";

const MenuCard = ({ items, category }) => {
  const [foodOption, setFoodOption] = useState({});
  const [optionErrorAnimation, setOptionErrorAnimation] = useState("");

  const {
    cart,
    isMenuClicked,
    addToCart,
    setErrorAnimation,
    currentColor,
    handleMenuClick,
    usersHotel,
  } = useStateContext();

  const letsGo = (offset) => {
    window.scrollTo({
      behavior: "smooth",
      top: offset,
    });
  };

  const errorCheck = (item) => {
    if (usersHotel.length !== 0) {
      if (item.options && foodOption.id === item.id.toString()) {
        //item.selectedOptions.push(foodOption);
        //setFoodOption({});
        addToCart(item, foodOption);
        console.log(foodOption);
      } else if (item.options === null) {
        addToCart(item);
      } else {
        setOptionErrorAnimation("animate-shake text-red-600");
        setTimeout(() => {
          setOptionErrorAnimation("");
        }, 500);
        clearTimeout();
      }
    } else {
      letsGo(0);
      setTimeout(() => {
        setErrorAnimation("animate-shake text-red-600");
      }, 750);
      clearTimeout();

      setTimeout(() => {
        setErrorAnimation("");
      }, 1500);
      clearTimeout();
    }
  };

  const handleOptions = (e) => {
    setFoodOption({ id: e.target.id, value: e.target.value });
  };

  return (
    <div className="min-h-20 font-bold bg-light-gray w-full rounded-xl my-2 px-5 cursor-pointer border-1 border-black">
      <div
        className="flex justify-between items-center h-20"
        onClick={() => {
          handleMenuClick(category);
          letsGo(150);
        }}
      >
        <div>{category[0].toUpperCase() + category.slice(1)}</div>
        <div className="text-3xl">
          {isMenuClicked[category] ? <IoChevronUp /> : <IoChevronDown />}
        </div>
      </div>
      <div className="gap-5">
        {isMenuClicked[category] &&
          items.map((item, index) => (
            <div
              className="relative bottom-5 rounded-2xl my-5 
             h-auto max-w-96 gap-5 text-xl bg-white border-1 border-black"
              key={index}
            >
              <div className="flex justify-center p-2">
                <img
                  className="rounded-lg w-72 h-48 object-cover"
                  src={item.image}
                  alt=""
                />
              </div>

              <div className="flex justify-center text-lg sm:text-2xl">
                <div className=" justify-around w-80 p-2">
                  {/* <div className="flex flex-wrap items-baseline text-xl"> */}
                  <div className="mr-2 min-w-24 text-xl">{item.name}</div>
                  {/* </div> */}
                  <div className="flex justify-between mt-2">
                    <div className="text-base">{item.calories} Kcal</div>
                    <div className="flex pl-5">${item.price}</div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <div className="flex text-lg text-wrap w-80 h-24 overflow-hidden py-2 px-5 font-normal">
                  {item.description.length >= 75
                    ? item.description.slice(0, 75) + "..."
                    : item.description}
                </div>
              </div>
              {item.options && (
                <form name="food_options">
                  <div
                    className={`flex justify-center pb-2 underline ${optionErrorAnimation}`}
                  >
                    Options
                  </div>
                  <div className="grid grid-cols-2 gap-1 text-base text-wrap">
                    {item.options.map((option, index) => (
                      <div
                        key={index}
                        className={`flex pl-5 pt-0 ${optionErrorAnimation}`}
                      >
                        <input
                          id={item.id}
                          type="radio"
                          value={option.name}
                          name="food_options"
                          onChange={handleOptions}
                        />
                        <label className="pl-2" htmlFor="html">
                          {option.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </form>
              )}
              <div className="flex justify-center m-5">
                <Button
                  size="md"
                  text="Add to Cart"
                  color="white"
                  bgColor={currentColor}
                  borderRadius="10px"
                  customFunc={() => errorCheck(item)}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default MenuCard;
